import React from "react";
import { NotionArticle } from "../../../components/TermsOfUse/NotionArticle.elements";

const PrivacyPolicy = () => {
  return (
    <>
      {
        <NotionArticle>
          <header>
            <h1 class="page-title">개인정보 처리 방침</h1>
            <p class="page-description"></p>
          </header>
          <div class="page-body">
            <p id="0fc8e1e4-09cf-4511-be0e-418cdedfd90b" class="">
              team CLARS 는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
            </p>
            <blockquote id="4d5d407b-1bdd-4115-b2d6-ae25c52b8ab4" class="">
              이 개인정보처리방침은 2023년 10월 1일부터 적용됩니다.
            </blockquote>
            <h3 id="18d03e3a-e498-499a-ae46-4fc43d2c93c1" class="">
              <strong>제1조(개인정보의 처리 목적)</strong>
            </h3>
            <p id="8375a629-7b3c-4d97-ae67-ce4b60bae985" class="">
              <strong>
                team CLARS는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
                예정입니다.
              </strong>
            </p>
            <p id="11ee4b1d-757b-4623-b0c5-4b5326bc1bd7" class="">
              1. 마케팅 및 광고에의 활용
            </p>
            <p id="87f1e5f0-4bc4-4944-86db-6e82637a2e2c" class="">
              회원의 서비스 이용에 대한 통계 수집을 목적으로 개인정보를 처리합니다.
            </p>
            <h3 id="bf114ce0-1326-4236-bd3d-1c14f8472c8b" class="">
              <strong>제2조(개인정보의 처리 및 보유 기간)</strong>
            </h3>
            <p id="e9fedb23-2b76-43a6-94c6-524b1b7ed06d" class="">
              ① team CLARS는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
            </p>
            <p id="de6f0ff7-8bb7-412d-b3ed-212254d9058a" class="">
              ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            </p>
            <ul id="863f6434-7535-415c-b303-64a160a98f6e" class="bulleted-list">
              <li>1. &lt;마케팅 및 광고에의 활용&gt;</li>
            </ul>
            <ul id="327a73a2-7f5a-45f1-85ab-1284acf1d81e" class="bulleted-list">
              <li>&lt;마케팅 및 광고에의 활용&gt; 와 관련한 개인정보는 수집.이용에 관한 동의일로부터 &lt;준영구&gt; 까지 위 이용목적을 위하여 보유.이용됩니다.</li>
            </ul>
            <ul id="30bc3256-7d31-4086-85aa-fe08efe67744" class="bulleted-list">
              <li>보유근거 : 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계</li>
            </ul>
            <ul id="e100c9e9-da37-4140-b980-78efd065de5f" class="bulleted-list">
              <li>관련법령 : 표시/광고에 관한 기록 : 6개월</li>
            </ul>
            <h3 id="47320a26-4b1d-4721-8465-26509b9a6f5f" class="">
              <strong>제3조(처리하는 개인정보의 항목)</strong>
            </h3>
            <p id="1182e523-59c0-451e-94be-ee707ddf939f" class="">
              ① team CLARS는 다음의 개인정보 항목을 처리하고 있습니다.
            </p>
            <ul id="255d4275-4650-46c0-b181-ae5eb5cb6698" class="bulleted-list">
              <li>1&lt; 마케팅 및 광고에의 활용 &gt;</li>
            </ul>
            <ul id="f11ecd80-ba64-434d-a3cb-7de87cd4f19a" class="bulleted-list">
              <li>필수항목 : 서비스 이용 기록</li>
            </ul>
            <p id="7f112f06-75f2-49a6-827d-3958cc68cb70" class=""></p>
            <h3 id="83b328d8-cc95-425b-9829-339c1b479217" class="">
              <strong>제4조(개인정보의 파기절차 및 파기방법)</strong>
            </h3>
            <p id="d184192d-1968-4fbe-8238-745c765e67df" class="">
              ① team CLARS는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
              따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. 1. 파기절차 team CLARS는 파기 사유가 발생한 개인정보를
              선정하고, team CLARS 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 2. 파기방법 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
            </p>
            <h3 id="f22f7886-07b6-4b9f-88f1-8bb0129f9675" class="">
              <strong>제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</strong>
            </h3>
            <p id="1da47f9b-5107-4621-8878-85a5115486c9" class="">
              ① 정보주체는 team CLARS에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다. ② 제1항에 따른 권리 행사는 team CLARS에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
              통하여 하실 수 있으며 team CLARS는 이에 대해 지체 없이 조치하겠습니다. ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지
              제11호 서식에 따른 위임장을 제출하셔야 합니다. ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그
              개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. ⑥ team CLARS은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
            </p>
            <h3 id="a37c98c2-b972-4bab-bce5-e26118570f6d" class="">
              <strong>제6조(개인정보의 안전성 확보조치에 관한 사항)</strong>
            </h3>
            <p id="fbe54346-b5ac-4e7a-9eb6-8cf70aeb33a3" class="">
              team CLARS<strong>은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</strong>
              1. 내부관리계획의 수립 및 시행 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
            </p>
            <h3 id="7dd1ed76-0b76-4dee-8e07-dff65b74c63f" class="">
              <strong>제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</strong>
            </h3>
            <p id="28609ed8-3675-47b4-bb6f-2a8ebb9e07d1" class="">
              team CLARS는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.
            </p>
            <h3 id="7426ae4f-1178-46a4-8ba2-e3ecd56b48fc" class="">
              제<strong>8조 (개인정보 보호책임자에 관한 사항</strong>
            </h3>
            <p id="226e571d-e5bd-4162-8272-51a19e7a29a7" class="">
              ① team CLARS는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. • ▶ 개인정보 보호책임자 • 성명: 박현호 • 소속: team
              CLARS • 연락처: <a href="mailto:teamclars2223@gmail.com">teamclars2223@gmail.com</a>, ② 정보주체께서는 team CLARS 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
              보호책임자 및 담당부서로 문의하실 수 있습니다. 박현호(개인정보 보호책임자)는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </p>
            <h3 id="f763626a-ace5-481f-a744-571ba4e30509" class="">
              <strong>제9조(정보주체의 권익침해에 대한 구제방법)</strong>
            </h3>
            <p id="fcd43674-a0fa-4c30-9e28-4a310f51f954" class="">
              정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
              바랍니다.1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)4. 경찰청 : (국번없이) 182
              (ecrm.cyber.go.kr)「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
              행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
            </p>
            <h3 id="d949583f-7041-44c4-93e6-6cb8003d7b4b" class="">
              <strong>제10조(개인정보 처리방침 변경)</strong>
            </h3>
            <p id="8cf0c6f8-c845-488d-bc22-b8aea344f339" class="">
              ① 이 개인정보처리방침은 2023년 10월 1일부터 적용됩니다. ② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
            </p>
            <ul id="2f4ad7e4-d51c-4220-9123-363becfad560" class="bulleted-list">
              <li>해당사항 없음</li>
            </ul>
          </div>
        </NotionArticle>
      }
    </>
  );
};

export default PrivacyPolicy;
