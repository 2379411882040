import React from 'react';
import { DownloadSection } from '../../components';

function Download() {
  return (
    <>
      <DownloadSection />
    </>
  );
}

export default Download;