

export const versions = [
      {
    date: "2024.01.22",
    version: "1.0.0",
    isPublic: true,
    improvements: ["모든 OS 홈 위젯 제공", "과거 메뉴 기반 사진 조회 기능 추가", "내일/모레의 메뉴 조회 기능 추가", "UI 개선"],
  },
    {
    date: "2024.01.16",
    version: "0.1.5(Beta)",
    isPublic: false,
    improvements: ["홈 위젯 추가"],
  },
    {
    date: "2023.12.30",
    version: "0.1.4(Beta)",
    isPublic: true,
    improvements: ["UI 개선", "성능 개선"],
  },
  {
    date: "2023.11.16",
    version: "0.1.3(Beta)",
    isPublic: true,
    improvements: ["업데이트 페이지 추가", "공지기능 개선", "버그 수정"],
  },
  {
    date: "2023.10.16",
    version: "0.1.2(Beta)",
    isPublic: true,
    improvements: ["App Store, Play Store 출시", "다크 모드 지원", "즐겨찾기 지원", "일부 카페 메뉴 제공"],
  },
  {
    date: "2023.10.05",
    version: "0.1.1(Beta)",
    isPublic: false,
    improvements: ["새로고침 기능 추가", "UI 개선"],
  },
  {
    date: "2023.9.20",
    version: "0.1.0(Beta)",
    isPublic: false,
    improvements: ["학생식당 / 기숙사 실시간 메뉴 지원"],
  },

]
